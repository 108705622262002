.c-button-group {
  display: inline-flex;

  > .c-button,
  > form {
    margin-left: 0 !important;
  }

  > :first-child,
  > :first-child > .c-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :last-child,
  > :last-child > .c-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > :not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  > *:not(:last-child),
  > *:not(:last-child) > form > .c-button {
    margin-right: -1px;
  }
}
