.c-suggestions {
  &__entry {
    &,
    &:active,
    &:focus,
    &:hover {
      /* override default anchor styles */
      color: theme('colors.gray.800');
    }

    cursor: pointer;
    display: block;
    padding: 0 0.4rem;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-tap-highlight-color: theme('colors.blue.600');
    width: 100%;

    em {
      text-decoration: underline;
      font-style: normal;
    }

    &__label {
      @apply text-sm;
    }

    &__details {
      @apply text-xs text-gray-500;
      @apply -mt-0.5;
    }

    &__selected {
      @apply invisible;
      @apply text-sm;
      @apply flex items-center;
      @apply text-blue-600;
      @apply pl-1 pr-2;
    }

    &[data-highlighted] {
      background-color: theme('colors.blue.100');
    }

    &[data-selected] &__label {
      @apply font-bold;
    }

    &[data-selected] &__selected {
      @apply visible;
    }

    &[data-type='group'] &__selected {
      @apply hidden;
    }

    &[data-type='group'] {
      color: theme('colors.gray.500');
      @apply font-medium;
      @apply text-sm;
      padding-left: 0.75em;
      cursor: default;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__no-entries {
    padding: 0.5em;
    color: theme('colors.gray.600');
    @apply text-sm my-1;
    overflow: hidden;
    white-space: nowrap;
  }

  &__search {
    padding: 0.5em 0 0.5em 0;
    @apply border-b;
    @apply border-gray-300;
    position: relative;

    input {
      width: 100% !important;
      @apply text-sm;
      padding: 0.3em 0.5em 0.3em 2.3em;

      &, &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &__search-icon {
    position: absolute;
    left: 0.75em;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;

    &::after {
      width: 0.8em;
      height: 0.8em;
      opacity: 0.5;
      /* FontAwesome magnifying-glass */
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z'/></svg>");
      background-repeat: no-repeat;
      content: "";
      display: block;
    }
  }
}
