.c-search-field {
  position: relative;

  @apply sm:w-full;

  &__dropdown {
    margin: 4px 0 0 0;
    width: 100%;
    max-width: 350px;
  }
}
