.c-select {
  --value-right-padding: 2rem;

  @apply w-full;

  min-width: 0; /* Important to adjust to parent size (https:/*css-tricks.com/flexbox-truncated-text/) */
  @apply font-normal;

  &__dropdown {
    position: relative;
  }

  &__value {
    cursor: pointer;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    user-select: none;

    padding-right: var(--value-right-padding);

    @apply print:pr-2.5;

    &::after {
      width: 0.95em;
      height: 0.95em;
      opacity: 0.5;
      /* fa-angle-down */
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><path d='M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z'/></svg>");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      right: 13px;

      @apply print:hidden;
    }
  }

  &__label,
  &__placeholder {
    text-overflow: ellipsis;
    overflow: hidden;

    /* ensure empty select takes the regular input height */
    &:empty::after {
      content: '\feff';
    }
  }

  &__placeholder {
    color: theme('colors.gray.500');
  }

  &__deselect {
    @apply text-xl;
    padding: 0 3px;
    position: absolute;
    top: 0;
    right: 1.3em;
    display: none;
    height: 100%;
    align-items: center;
    outline: none;

    &::after {
      width: 0.75em;
      height: 0.75em;
      opacity: 0.5;
      /* FontAwesome xmark */
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><path d='M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z'/></svg>");
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      display: block;

      @apply print:hidden;
    }

    &:hover {
      &::after {
        opacity: 0.7;
      }
    }
  }

  &__list {
    margin: 4px 0 0 0;
    min-width: 100% !important; /* override .dropdown min-width */
    max-width: 300px;
  }

  &__select {
    /* Make invisible, using this instead of display:none to make sure */
    /* the focus events still work on the select. */
    opacity: 0;
    height: 0 !important;
    width: 0 !important;
    overflow: hidden;
    position: absolute;
    border: 0;
  }

  &.open {
    .c-select__list {
      display: block;
    }

    &:not(.c-select--as-button) .c-select__value {
      @apply border-blue-600 !important;
      @apply ring-2 ring-blue-500 ring-opacity-50;
      outline: 0;
    }
  }

  &.disabled {
    .c-select__value {
      @apply !bg-gray-100;
      @apply !text-gray-500;
      @apply cursor-not-allowed;
    }
  }

  &.invalid {
    .c-select__value {
      /* make sure this matches with the invalid styling in forms.css */
      @apply border-red-400 !important;
      @apply text-red-900 placeholder-red-300 !important;
      @apply focus:border-red-500 !important;
      @apply focus:ring-red-500 focus:ring-opacity-50 !important;
    }
  }

  &--with-deselect {
    .c-select__value {
      padding-right: 2.6rem;

      @apply print:pr-2.5;
    }
  }

  &--as-button {
    .c-select__label,
    .c-select__placeholder {
      @apply font-semibold;
      @apply text-gray-600;

      line-height: 1.25rem;
      padding: 8px 32px 8px 9px;
      max-width: 250px;
      text-overflow: ellipsis;
      min-height: initial;
    }
  }
}
