:root {
  --max-width: 1280px; /* containers */
  --navigation-width: 280px;
  --footer-height: 0; /* needed for body padding (defined in pistachio) */

  --content-width: calc(100vw - var(--navigation-width) - 1px);
  --content-width-scrollbar: calc(var(--content-width) - 17px); /* 17px for scrollbar */
}

.content-wrapper {
  @apply mx-0 my-14 sm:mx-0 sm:my-6;
  @apply print:mt-4 print:mb-0;
}

body {
  min-width: var(--max-width);
}

body.sm {
  min-height: 100%;
  min-width: initial;
}

body.sm .wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

body.plain {
  min-width: initial;
}

.turbo-progress-bar {
  @apply bg-blue-700;

  height: 2px;
}

.translation_missing {
  color: red !important;
}


/* MOBILE FOOTER */
footer.mobile-footer {
  background-color: theme('colors.gray.50');
  border-top: 2px solid theme('colors.gray.200');
  bottom: 0;
  padding: 0.75em 0.75em 0.5em !important;
  position: fixed;
  margin: 0;
  width: 100%;
  height: 50px;

  a {
    color: theme('colors.gray.600');
    float: right;
    font-size: .8em;
    @apply font-semibold;
    line-height: 2em;
    margin-left: .75em
  }
}

.administrative-navigation {
  a {
    @apply rounded px-3 py-2 flex items-center text-sm font-medium border-l-4;

    &:not(.active) {
      @apply text-gray-600 hover:bg-gray-100 hover:text-gray-900 border-transparent;
    }

    &.active {
      @apply bg-blue-50 text-blue-600 hover:bg-blue-50 hover:text-blue-700 border-blue-700;
    }
  }
}

.g-gs-highlight {
  @apply relative;

  &::before {
    content: '';

    @apply animate-pulseborder;
    @apply rounded;
    @apply absolute inset-0;
  }
}
