/* GENERAL LAYOUT */
@media print {
  body {
    print-color-adjust: exact;
  }
}

a {
  @apply print:text-gray-800;
}

input {
  @apply print:border-none !important;
  @apply print:shadow-none !important;
}

.c-button,
.c-footer,
.c-ical-feed-configurator-link,
.c-flashes,
.flatpickr-calendar {
  @apply print:hidden !important;
}

/* ABSENCE CALENDAR */
.reports-calendar .absence-calendar {
  @apply print:my-1 print:mx-0 !important;
}
