.c-button {
  --background: theme('colors.blue.700');
  --background-hover: theme('colors.blue.600');
  --background-hover-inverted: theme('colors.blue.50');
  --background-active: theme('colors.blue.700');
  --background-active-inverted: theme('colors.blue.100');
  --text-color: white;
  --text-color-inverted: theme('colors.blue.700');
  --border-color-inverted: theme('colors.blue.400');
  --disabled-opacity: 0.5;

  @apply inline-flex items-center justify-center gap-x-1;
  @apply rounded-lg border-0 shadow-border;
  @apply text-sm antialiased font-semibold;
  @apply text-center no-underline whitespace-nowrap;
  @apply px-3 py-2;

  color: var(--text-color);
  background: var(--background);

  &.c-button--icon-only .icon3000 {
    @apply text-base;
    padding: 2px 0px;
  }

  &:hover, &.hover {
    color: var(--text-color);

    /* enabled */
    &:not(:disabled):not(.disabled) {
      background: var(--background-hover);
      cursor: pointer;
    }
  }

  &:active, &.active {
    color: var(--text-color);

    &:not(:disabled):not(.disabled) {
      background: var(--background-active);
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15),
                  inset 0 1px 3px rgba(0, 0, 0, 0.2);
    }
  }

  &:focus, &.focus {
    color: var(--text-color);
    outline: none;
    @apply ring ring-blue-200;

    z-index: 1;
  }

  &:disabled, &.disabled {
    color: var(--text-color);
    opacity: var(--disabled-opacity);
    cursor: not-allowed;
  }

  .icon {
    margin-right: 0.25em;
  }

  &--xs {
    @apply text-xs;
    @apply px-2 py-1;

    &.c-button--icon-only .icon3000 {
      @apply text-sm;
      padding: 1px 0px;
    }
  }

  &--sm {
    @apply px-2.5 py-1.5;

    &.c-button--icon-only .icon3000 {
      @apply text-base;
      padding: 2px 0px;
    }
  }

  &--lg {
    @apply px-3.5 py-2.5;
    @apply text-base;

    &.c-button--icon-only .icon3000 {
      @apply text-lg;
      padding: 3px 0px;
    }
  }

  &--secondary {
    --background: theme('colors.gray.100');
    --background-hover: theme('colors.gray.200');
    --background-hover-inverted: theme('colors.gray.100');
    --background-active: theme('colors.gray.200');
    --background-active-inverted: theme('colors.gray.100');
    --text-color: theme('colors.gray.600');
    --text-color-inverted: theme('colors.gray.700');
    --border-color-inverted: theme('colors.gray.300');
    --disabled-opacity: 0.6;
  }

  &--positive {
    --background: theme('colors.green.600');
    --background-hover:  theme('colors.green.700');
    --background-hover-inverted: theme('colors.green.50');
    --background-active: theme('colors.green.700');
    --background-active-inverted: theme('colors.green.100');
    --text-color: white;
    --text-color-inverted: theme('colors.green.700');
    --border-color-inverted: theme('colors.green.500');
    --disabled-opacity: 0.5;
  }

  &--negative {
    --background: theme('colors.red.600');
    --background-hover: theme('colors.red.500');
    --background-hover-inverted: theme('colors.red.50');
    --background-active: theme('colors.red.500');
    --background-active-inverted: theme('colors.red.100');
    --text-color: white;
    --text-color-inverted: theme('colors.red.600');
    --border-color-inverted: theme('colors.red.500');
    --disabled-opacity: 0.5;
  }

  &--inverted {
    --background: transparent;
    --background-hover: var(--background-hover-inverted);
    --background-active: var(--background-active-inverted);
    --text-color: var(--text-color-inverted);
    --disabled-opacity: 0.6;

    box-shadow: inset 0 0 0 1px var(--border-color-inverted);
  }

  &--plain {
    --background: transparent;
    --background-hover: transparent;
    --background-active: transparent;
    --text-color: theme('colors.gray.600');
    --disabled-opacity: 0.6;

    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    &:hover, &.hover {
      &:not(:disabled):not(.disabled) {
        @apply text-gray-600;
      }
    }

    &:focus, &.focus {
      @apply ring ring-blue-200;
    }

    &:active, &.active {
      @apply text-gray-600;

      &:not(:disabled):not(.disabled) {
        @apply text-gray-600;
        box-shadow: none;
      }
    }
  }
}
