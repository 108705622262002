@keyframes blink {
  from { @apply bg-yellow-200; }
  to { background-color: transparent; }
}

.c-m-custom-fields {
  .highlight-row {
    animation: blink 1s normal ease-in-out;
  }
}
