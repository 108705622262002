.c-dropdown {
  display: inline-block;

  .c-dropdown__toggle {
    width: inherit;
    cursor: pointer;
  }

  &__window {
    @apply absolute top-0 left-0;
    @apply py-1;

    z-index: calc(var(--z-index-top) + 1);
  }

  &__link {
    a,
    .button_to > [type="submit"] {
      @apply block w-full;
      @apply text-left;
      @apply normal-case;
      @apply px-4 py-2;
      @apply text-sm text-gray-700;
      @apply leading-5;
      @apply lg:whitespace-nowrap;

      &:hover {
        color: white;
        background: theme('colors.blue.600');
      }

      &.disabled {
        @apply text-gray-400;
        @apply cursor-not-allowed;
      }
    }

    .button_to {
      @apply block w-full;
    }
  }
}
