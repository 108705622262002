.c-modal {
  transition: opacity 0.25s ease;
  z-index: calc(var(--z-index-top) + 1);

  @apply hidden;
  @apply justify-center items-start;
  @apply fixed inset-0;
  @apply bg-black bg-opacity-40;
  @apply overflow-auto;

  &__inner {
    transition: opacity 0.25s ease;
    max-width: 850px;
    max-height: 95%;

    @apply relative;
    @apply overflow-visible;
    @apply p-0;
    @apply mt-2 lg:mt-24;
    @apply sm:w-pct-97;

  }

  &__content {
    @apply rounded-lg shadow-xl;
    @apply bg-white;
    @apply p-4 lg:p-6;
    @apply overflow-visible;
    @apply sm:text-sm;

    h1 {
      @apply mb-4;
      @apply text-2xl font-semibold sm:text-lg;
    }
  }

  &__title {
    @apply flex justify-center items-center;
    @apply -mt-4 -ml-4 -mr-4 lg:-mt-6 lg:-ml-6 lg:-mr-6;
    @apply mb-4;
    @apply py-2 px-4;
    @apply rounded-t-lg;
    @apply bg-gray-100;
    @apply text-gray-600 text-center font-semibold;
    @apply border-b border-gray-300;
    @apply relative;
  }

  &__actions,
  .c-modal__content .form-actions {
    @apply flex;
    @apply w-auto;
    @apply -mb-4 -ml-4 -mr-4 lg:-mb-6 lg:-ml-6 lg:-mr-6;
    @apply mt-8;
    @apply py-3 px-4 lg:px-6;
    @apply rounded-b-lg;
    @apply bg-gray-50;
  }
}
