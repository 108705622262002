.c-label {
  @apply inline-flex items-center justify-center;
  @apply px-2 py-1;
  @apply shadow-border-xs rounded-md;
  @apply font-medium !leading-none;
  @apply bg-gray-100 border-gray-400 text-gray-500;
  @apply cursor-default;

  &[data-tooltip]:not([data-tooltip=""]) {
    @apply cursor-help;
  }

  &--positive,
  &--green {
    @apply bg-green-100 text-green-700;
  }

  &--orange {
    @apply bg-orange-100 text-orange-700;
  }

  &--yellow {
    @apply bg-yellow-100 text-yellow-700;
  }

  &--blue {
    @apply bg-blue-100 text-blue-700;
  }

  &--purple {
    @apply bg-purple-100 text-purple-700;
  }

  &--negative,
  &--red {
    @apply bg-red-100 text-red-700;
  }
}
