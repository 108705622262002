
.c-tooltip {
  --background-color: theme('colors.gray.800');
  --border-color: theme('colors.gray.800');

  @apply py-1 px-2;
  @apply text-sm text-white font-medium;

  overflow: initial;
  max-width: 350px;
  z-index: calc(var(--z-index-top) + 1);
}
