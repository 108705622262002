.base-chart {
  @apply w-full h-full;

  .line {
    stroke: theme('colors.blue.500');
    stroke-width: 2;
    fill: none;
  }

  .point {
    stroke: theme('colors.blue.500');
    fill: theme('colors.blue.300');
  }

  .bar {
    fill: theme('colors.blue.500');
  }

  .axis {
    @apply pointer-events-none;

    text {
      @apply text-xs;
      @apply text-gray-500;
    }

    path {
      stroke: theme('colors.gray.300');
    }
  }

  .zeroline {
    stroke: theme('colors.gray.300');
  }

  .monthBar {
    @apply pointer-events-none;

    fill: none;

    &:nth-child(odd) {
      fill: theme('colors.gray.100');
    }
  }

  .hoverBar {
    @apply cursor-pointer;

    opacity: 0;
    stroke: theme('colors.blue.400');
    fill: theme('colors.blue.400');

    &.active {
      opacity: 0.3;
    }
  }
}
