.c-reporting {
  @apply w-fit mx-auto;

  &__filter {
    width: var(--content-width-scrollbar);
    @apply z-20;
    @apply sticky left-0;
  }

  &__report {
    min-width: min(var(--max-width), var(--content-width-scrollbar)); /* minimum width of container, span to content width */

    @apply w-fit;
    @apply mx-auto;
    @apply mt-6 mb-8;
    @apply px-11;
  }

  &__header {
    @apply flex justify-between items-center;
  }

  &__data {
    @apply py-3;
  }

  table {
    td, th {
      @apply text-left;
      @apply text-sm;
      @apply align-top;

      min-width: 100px;
      max-width: 300px;
      @apply border-b;

      @apply p-1 px-3;
      &:first-child { @apply pl-1; }
      &:last-child { @apply pr-1; }
    }

    tr {
      > td:first-child {
        @apply whitespace-nowrap;
        @apply sticky left-0;
        @apply bg-white;
    }
    }

    tr:hover {
      td {
        @apply !bg-gray-100;
      }
    }

    [data-row-style='header'] {
      @apply text-gray-500;
      @apply text-xs;
      @apply border-b;

      @apply sticky -top-px bg-white z-10;

      td, th {
        @apply pt-3;
        @apply font-semibold;
      }
    }

    [data-row-style='group'] {
      @apply font-semibold;
      @apply border-b;
    }

    [data-row-style='total'] {
      @apply font-semibold;
    }

    [data-column-style='numeric'], [data-column-style='currency'], [data-column-style='duration'] {
      @apply text-right;
    }
  }
}
