
.c-popover {
  max-width: 600px;
  overflow: initial;

  &__title {
    @apply p-2;
    @apply bg-gray-100 rounded-t-lg;
    @apply border-b border-gray-300;
    @apply text-gray-600 text-xs font-semibold text-center;
  }

  &__content {
    @apply overflow-hidden;

    min-width: 150px;
  }

  &__loading {
    @apply p-2;
    @apply text-center;

    min-width: 150px;
  }


  @apply sm:top-unset !important;
  @apply sm:left-0 !important;
  @apply sm:right-0 !important;
  @apply sm:bottom-0 !important;
  @apply sm:m-0 !important;
  @apply sm:fixed !important;
  @apply sm:transform-none !important;
  @apply sm:border-0 sm:border-t-2;
  @apply sm:rounded-none;


  &[data-placement="top"],
  &[data-placement="bottom"] {
    @apply sm:top-unset !important;
  }

  &[data-placement="left"],
  &[data-placement="right"] {
    @apply sm:left-0 !important;
  }

  [data-arrow] {
    @apply sm:hidden !important;
  }

  .c-button {
    @apply sm:w-full sm:max-w-sm;
  }
}
