.c-notice {
  @apply flex items-center;
  @apply p-4;
  @apply rounded-lg;
  @apply text-sm font-medium;
  @apply cursor-default;

  p {
    @apply text-sm;
  }

  a {
    @apply underline font-semibold;
  }

  .icon3000 {
    @apply min-h-4 min-w-4;
    @apply mr-2 mt-[2px];
    @apply text-base leading-5;
  }

  &--notice {
    @apply bg-blue-50;
    @apply text-blue-700;

    a {
      @apply text-blue-800;

      &:hover {
        @apply text-blue-900;
      }
    }

    .icon3000 {
      @apply text-blue-500;
    }
  }

  &--alert {
    @apply bg-yellow-100;
    @apply text-yellow-700;

    a {
      @apply text-yellow-800;

      &:hover {
        @apply text-yellow-900;
      }
    }

    .icon3000 {
      @apply text-yellow-500;
    }
  }

  &--error {
    @apply bg-red-100;
    @apply text-red-600;

    a {
      @apply text-red-700;

      &:hover {
        @apply text-red-800;
      }
    }

    .icon3000 {
      @apply text-red-400;
    }
  }


  &--success {
    @apply bg-green-100;
    @apply text-green-700;

    a {
      @apply text-green-700;

      &:hover {
        @apply text-green-800;
      }
    }

    .icon3000 {
      @apply text-green-400;
    }
  }

  &--neutral {
    @apply bg-gray-100;
    @apply text-gray-600;

    a {
      @apply text-gray-600;

      &:hover {
        @apply text-gray-700;
      }
    }

    .icon3000 {
      @apply text-gray-500;
    }
  }
}
