:root {
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --input-padding: 9px;
  --dropdown-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2), /* <- shadow */
                         0 0 0 1px rgba(0, 0, 0, 0.1); /* <- border */
  --z-index-top: 9999;
}

html {
  height: 100%;
  font-family: var(--font-family);
}

body {
  @apply min-h-full;
  @apply bg-white;
  @apply antialiased tracking-tight;
  @apply text-gray-800;
}

* {
  /* Prevent gray blink when touching elements on mobile */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[role="button"],
button,
input[type="submit"] {
  @apply cursor-pointer;
  @apply antialiased tracking-tight;
}

a {
  @apply text-blue-700 hover:text-blue-800 active:text-blue-800 focus:text-blue-800;
  @apply no-underline;
}

b,
strong {
  @apply font-semibold;
}

hr {
  margin: 1.5em 0;
}

svg g,
svg text {
  font-family: inherit;
}

table {
  border-collapse: collapse;
  width: 100%;
}

p + p {
  @apply mt-3;
}

.prose {
  figure {
    a,
    figcaption {
      @apply no-underline;
    }
  }

  table td > ul,
  table td > ol {
    @apply my-0;

    > :first-child {
      @apply mt-0;
    }

    > :last-child {
      @apply mb-0;
    }
  }

  .footnotes {
    @apply text-sm;

    li {
      @apply my-1;
    }

    li > p {
      @apply m-0;
    }
  }
}
