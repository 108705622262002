body {
  position: relative;

  &.lg {
    min-height: 100vh;
    padding-bottom: var(--footer-height);

    @apply print:min-h-0 print:pb-0;
  }
}

.wrapper {
  width: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  @apply print:block;
}

.container {
  max-width: var(--max-width);

  @apply mx-auto;
  @apply px-11;
}

.container.full-width {
  @apply max-w-none;
  @apply px-4;
}
