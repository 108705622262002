.c-grid-picker {
  --option-size: 1.75rem;

  display: flex;
  justify-content: flex-start;
  position: relative;

  &__value {
    width: 2.625em;
    height: 2.625em;
    border: 1px solid theme('colors.gray.300');
    @apply rounded;
    padding: 0.25em;
    background: white;
  }

  &__options {
    padding: 0 0.5em;
    display: grid;
    grid: auto-flow / repeat(6, var(--option-size));
    grid-column-gap: 3px;
    grid-row-gap: 3px;
  }

  &__option {
    border: 1px solid white;
    @apply rounded;
    width: var(--option-size);
    height: var(--option-size);
    color: theme('colors.gray.800');
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3px;

    &:hover {
      border-color: theme('colors.gray.300');
      color: theme('colors.gray.800');
    }

    &:visited,
    &:active,
    &:focus {
      color: theme('colors.gray.800');
    }

    &--blank {
      background: linear-gradient(-45deg, #fff 48%, theme('colors.gray.300') 48%, theme('colors.gray.300') 52%, #fff 53%);
      border-color: theme('colors.gray.400');
    }
  }

  &__value,
  &__option {
    > * {
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

  .c-dropdown {
    display: flex;
    position: initial;
  }

}
