.c-month-calendar {
  --grid-z-index: 5;

  display: grid;
  grid-template-rows: 18px repeat(auto-fill, 18px);
  grid-auto-rows: 18px;
  grid-auto-columns: 1fr;
  gap: 2px;

  .mc-weekday-header {
    @apply text-center text-xs font-semibold;
  }

  .mc-cell {
    @apply border rounded;
    @apply text-right p-1 text-xs;
    @apply cursor-pointer;

    &[data-is-today] { @apply border-2 border-blue-600; }

    z-index: var(--grid-z-index);
  }

  .mc-event {
    @apply pointer-events-none;

    &[data-layer='foreground'] {
      z-index: calc(var(--grid-z-index) + 1);
      @apply flex items-center rounded px-1 text-xs truncate mt-px;

      &[data-starts='earlier'] { @apply ml-0 rounded-l-none; }
      &[data-ends='later'] { @apply mr-0 rounded-r-none; }
    }

    &[data-layer='background'] {
      z-index: calc(var(--grid-z-index) - 1);

      @apply m-px; /* don't exceed border */

      &[data-type='unemployment'] {
        @apply bg-gray-200 opacity-70;
      }

      &[data-type='weekly_schedule_absence'] {
        @apply bg-gray-200 opacity-70;
        &[data-wday='0'], &[data-wday='6'] { @apply bg-blue-100 opacity-100; } /* weekend */
      }

      &[data-type='public_holiday'] {
        @apply opacity-10;
        @apply bg-transparent;
        background-image: linear-gradient(-45deg, black 25%, transparent 25%, transparent 50%, black 50%, black 75%, transparent 75%, transparent);
        background-size: 10px 10px;
      }

      &[data-type='birthday'] {
        margin-left: auto;
        width: 0;
        height: 0;
        background: transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid var(--event-color-birthday);
      }
    }
  }

}
