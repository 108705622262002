#shifts {
  --actions-height: 137px;
  --days-height: 46px;
  --grid-col-min-width: 100px;
  --grid-row-height: 70px;
  --grid-z-index: 5;

  @apply text-sm;

  .shifts-actions {
    width: var(--content-width);
    @apply bg-white;
    @apply z-40;
    @apply sticky top-0 left-0;
  }

  .shifts-grid {
    @apply grid;
    @apply w-full;

    grid-template-columns: 200px 100px 1fr;
  }

  .shifts-header {
    @apply sticky top-[--actions-height] z-20;
    @apply bg-gray-100;
    @apply border-t !border-b-2;
  }

  .shifts-header-cell {
    @apply min-w-[--grid-col-min-width];
  }

  #summary_row .shifts-header {
    top: calc(var(--actions-height) + var(--days-height));
  }

  .shifts-grid-header {
    @apply grid grid-flow-col;

    grid-auto-columns: 1fr;
  }

  .shifts-grid-cells {
    @apply grid grid-flow-col;

    grid-auto-columns: 1fr;
    grid-auto-rows: auto minmax(var(--grid-row-height), auto);
  }

  .shifts-cell {
    @apply border-b border-r;
    @apply min-w-[--grid-col-min-width];

    &[data-drag-destination]::before {
      content: '';
      @apply border border-yellow-400;
      @apply absolute inset-0 w-full h-full z-10;
    }

    [data-layer='event'] {
      z-index: calc(var(--grid-z-index) + 1);
    }

    [data-layer='shift'] {
      z-index: calc(var(--grid-z-index) + 2);
      @apply m-1;
    }

    [data-layer='background'] {
      z-index: calc(var(--grid-z-index) - 1);

      &[data-type='unemployment'] {
        z-index: calc(var(--grid-z-index) - 2);
        @apply bg-gray-100 opacity-70;
      }

      &[data-type='weekly_schedule_absence'] {
        z-index: calc(var(--grid-z-index) - 2);
        @apply bg-gray-100 opacity-70;
        &[data-wday='0'], &[data-wday='6'] { @apply bg-blue-100 opacity-100; } /* weekend */
      }

      &[data-type='public_holiday'] {
        @apply opacity-10;
        @apply bg-transparent;
        background-image: linear-gradient(-45deg, black 25%, transparent 25%, transparent 50%, black 50%, black 75%, transparent 75%, transparent);
        background-size: 10px 10px;
      }
    }
  }

  .shifts-shift {
    &.selected {
      @apply bg-yellow-100;
      @apply ring-2 ring-yellow-400;
      @apply text-black;
    }
  }

  .shifts-day {
    &.selected {
      @apply bg-purple-100;
      @apply border-solid border-purple-400;
      @apply visible !important;
    }
  }
}
