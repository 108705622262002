@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-duration-3000 {
    animation-duration: 3000ms;
  }

  .animation-paused {
    animation-play-state: paused;
  }
}

@layer components {
  .box {
    @apply rounded-lg shadow-sm ring-1 ring-gray-600/[.10] bg-white;
  }

  .dropdown {
    --background-color: white;
    --border-color: theme('colors.gray.200');
    --arrow-size: 8px;

    @apply absolute;
    @apply hidden; /* hide initially */
    @apply lg:min-w-36;
    @apply overflow-hidden;
    @apply rounded-lg;
    @apply text-gray-800;

    background: var(--background-color);
    z-index: var(--z-index-top);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2), /* <- shadow */
                0 0 0 1px var(--border-color); /* <- border */

    /* arrow */
    [data-arrow] {
      position: absolute;
      background: red;
    }
    [data-arrow]::before,
    [data-arrow]::after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      pointer-events: none;
    }

    &[data-placement="top"] {
      [data-arrow] {
        margin-left: calc(var(--arrow-size) * -1);
        bottom: 0;
      }

      [data-arrow]::after {
        bottom: calc(var(--arrow-size) * -2);
        border-color: var(--border-color) transparent transparent transparent;
        border-width: var(--arrow-size);
      }
    }

    &[data-placement="bottom"] {
      [data-arrow] {
        margin-left: calc(var(--arrow-size) * -1);
        top: 0;
      }

      [data-arrow]::after {
        top: calc(var(--arrow-size) * -2);
        border-color: transparent transparent var(--border-color) transparent;
        border-width: var(--arrow-size);
      }
    }

    &[data-placement="left"] {
      [data-arrow] {
        margin-top: calc(var(--arrow-size) * -1);
        right: 0;
      }

      [data-arrow]::after {
        right: calc(var(--arrow-size) * -2);
        border-color: transparent transparent transparent var(--border-color);
        border-width: var(--arrow-size);
      }
    }

    &[data-placement="right"] {
      [data-arrow] {
        margin-top: calc(var(--arrow-size) * -1);
        left: 0;
      }

      [data-arrow]::after {
        left: calc(var(--arrow-size) * -2);
        border-color: transparent var(--border-color) transparent transparent;
        border-width: var(--arrow-size);
      }
    }
  }
}

/* For the space-* utilities, Tailwind CSS does not consider elements with a `hidden` attribute.
As our Rails forms include a hidden input for the `authenticity_token` which doesn't have a `hidden`
attribute set, this makes sure elements after this input don't have a space before. */
#app .space-y-0\.5 > input[type="hidden"] + :not([hidden]),
#app .space-y-1    > input[type="hidden"] + :not([hidden])
#app .space-y-1\.5 > input[type="hidden"] + :not([hidden])
#app .space-y-2    > input[type="hidden"] + :not([hidden])
#app .space-y-2\.5 > input[type="hidden"] + :not([hidden])
#app .space-y-3    > input[type="hidden"] + :not([hidden])
#app .space-y-3\.5 > input[type="hidden"] + :not([hidden])
#app .space-y-4    > input[type="hidden"] + :not([hidden])
#app .space-y-5    > input[type="hidden"] + :not([hidden])
#app .space-y-6    > input[type="hidden"] + :not([hidden])
#app .space-y-7    > input[type="hidden"] + :not([hidden])
#app .space-y-8    > input[type="hidden"] + :not([hidden]) {
  @apply mt-0;
}
