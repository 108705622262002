/* Based on the css classes generated by @tailwindcss/forms */

label,
label > span,
input {
  @apply block;
}

label {
  @apply block text-sm font-medium text-gray-700;
}

.input,
[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  @apply w-full;
  @apply bg-white;
  @apply border border-gray-300;
  @apply rounded-lg shadow-sm;
  @apply text-gray-800 leading-5 text-sm font-normal tracking-normal;
  @apply hover:text-gray-800 active:text-gray-800 focus:text-gray-800;
  @apply focus:border-blue-500;
  @apply focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50;

  padding: var(--input-padding);
  line-height: 1.25em;

  &.invalid {
    @apply border-red-400;
    @apply text-red-900 placeholder-red-300;
    @apply focus:border-red-500;
    @apply focus:ring-red-500 focus:ring-opacity-50;
  }

  &::placeholder {
    @apply text-gray-500;
  }

  &:disabled {
    @apply bg-gray-100;
    @apply text-gray-500;
    @apply cursor-not-allowed;
  }
}

select,
[multiple] {
  @apply pr-7;

  &:disabled {
    /* Some browsers set a lower opacity for disabled selects, override it */
    @apply opacity-100;
  }
}

[type="time"],
[type="date"] {
  @apply appearance-none;

  &::-webkit-date-and-time-value {
    @apply text-left leading-5;
    @apply flex items-center;
    @apply whitespace-nowrap;

    /* Chrome on Android adds some annoying margins */
    @apply ml-0;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button,
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    @apply hidden;
    @apply appearance-none;
  }
}

[type="checkbox"],
[type="radio"] {
  @apply h-4 w-4;
  @apply rounded;
  @apply text-blue-600;
  @apply shadow-sm;
  @apply border-gray-300;
  @apply focus:border-blue-300;
  @apply focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-80;

  &:disabled {
    @apply bg-gray-200;
    @apply text-gray-200 hover:text-gray-200;
    @apply border-gray-300 hover:border-gray-300;
    @apply cursor-not-allowed;
  }
}

[type="radio"] {
  @apply rounded-full;
}

textarea {
  min-height: 50px;
}

/* Helper to wrap form actions and style them
accordingly in modals */
.form-actions {
  @apply relative;
  @apply flex items-center;
  @apply mt-8;
  @apply col-span-full;

  .icon-trash-can {
    @apply text-gray-500 hover:text-red-500 text-sm;
  }
}
