table.c-table {
  @apply text-sm;
  @apply divide-y divide-gray-200;
  @apply sm:table-fixed;

  &[data-border-top] {
    @apply border-t border-gray-200;
  }

  &[data-box] {
    @apply bg-white rounded-lg;

    thead:first-child > tr:first-child > th:first-child,
    tbody:first-child > tr:first-child > td:first-child {
      @apply rounded-tl-lg;
    }
    thead:first-child > tr:first-child > th:last-child,
    tbody:first-child > tr:first-child > td:last-child {
      @apply rounded-tr-lg;
    }
  }

  &[data-box],
  &[data-rounded-bottom] {
    tbody:last-child > tr:last-child > td:first-child,
    tfoot > tr:last-child > td:first-child {
      @apply rounded-bl-lg;
    }
    tbody:last-child > tr:last-child > td:last-child,
    tfoot > tr:last-child > td:last-child {
      @apply rounded-br-lg;
    }
  }

  &[data-compact] {
    > tbody > tr > td {
      @apply py-[0.4rem];
    }

    > thead > tr > th:first-child,
    > tbody > tr > td:first-child,
    > tfoot > tr > td:first-child {
      @apply pl-2;
    }

    > thead > tr > th:last-child,
    > tbody > tr > td:last-child,
    > tfoot > tr > td:last-child {
      @apply pr-2;
    }
  }

  > thead > tr {
    @apply bg-gray-100;

    > th {
      @apply px-6 py-3;
      @apply text-left leading-5 font-medium text-gray-500;
    }
  }

  > tbody {
    @apply divide-y divide-gray-200;
    @apply sm:border-t-0 !important;

    > tr > td {
      @apply leading-5;
      @apply px-6 py-4 sm:px-4;
    }

    > tr.clickable-row {
      @apply hover:bg-gray-50;

      td {
        @apply relative;
      }
    }

    > tr {
      .marker {
        @apply invisible;
      }
    }

    > tr.selected {
      @apply bg-gray-50;

      .marker {
        @apply visible;
        @apply absolute inset-y-0 left-0 w-1;
      }
    }
  }

  > tfoot {
    @apply lg:bg-white;

    > tr > td {
      @apply px-6 py-2 sm:px-4;
      @apply font-semibold;
    }
  }
}
