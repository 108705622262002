.c-unsupported-browser {
  /* No magic and no Tailwind here, we want this to work in all the old crappy browsers. */

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  border-bottom: 1px solid theme('colors.gray.200');
  padding: 8em 1em;
  z-index: 10000;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  color: #000;
  background-color: #fff;

  &__banner {
    position: relative;
    height: initial;
    width: initial;
    padding: 4em 1em;
    background: theme('colors.yellow.100');
    border-bottom: 1px solid theme('colors.yellow.200');
  }

  a {
    text-decoration: underline;
    color: #000;
  }

  p {
    margin: 0 auto;
    max-width: 975px;
  }

  table {
    width: auto;
    margin: 0 auto;
    margin-top: 2em;

    td {
      padding: 0 1em;

      @apply sm:py-0 sm:px-3;
      @apply sm:text-base;

      img {
        @apply sm:w-9 sm:h-9;
      }
    }
  }
}
