@import "croppie/croppie.css";

.c-avatar-upload {
  .croppie-container {
    position: relative;
  }

  .cr-slider-wrap {
    bottom: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .cr-slider-wrap input {
    background: rgba(255, 255, 255, .8);
    border-radius: 3px;
    margin: 0 auto 0.5em;
    padding: 0.5em;
    width: 75%;
  }

  .cr-slider::-webkit-slider-runnable-track {
    background: theme('colors.gray.600');
  }

  .cr-slider::-webkit-slider-thumb {
    background: theme('colors.gray.600');
  }

  .cr-slider::-moz-range-track {
    background: theme('colors.gray.600');
  }

  .cr-slider::-moz-range-thumb {
    background: theme('colors.gray.600');
  }

  .cr-slider::-ms-fill-lower {
    background: theme('colors.gray.600');
  }

  .cr-slider::-ms-fill-upper {
    background: theme('colors.gray.600');
  }

  .cr-slider::-ms-thumb {
    background: theme('colors.gray.600');
  }
}
