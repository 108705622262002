.c-manage_user_charts_break-deficits {
  @apply relative; /* for year selector */

  .c-manage_user_charts_year-selector { @apply hidden; }
  &:hover { .c-manage_user_charts_year-selector { @apply inline-block; } }

  .breakRequired {
    fill: theme('colors.blue.500');
    opacity: 1;
  }

  .breakDeficit {
    &.good {
      fill: theme('colors.blue.200');
    }

    &.warning {
      fill: theme('colors.yellow.400');
    }

    &.danger {
      fill: theme('colors.red.200');
    }
  }
}
