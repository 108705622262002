body {
  --event-color-non_work_day: theme('colors.gray.200');
  --event-color-public_holiday: theme('colors.gray.300');
  --event-color-birthday: theme('colors.purple.400');

  /* AbsenceType / ExpenseCategory colors */
  --event-color-gray: #9fa6b2;
  --event-color-dark-violet: #a1a2be;
  --event-color-hakuna-blue: #759dd7;
  --event-color-blue: #7da3f6;
  --event-color-violet: #d29ee8;
  --event-color-soft-blue: #88bff5;
  --event-color-orange: #ffb56f;
  --event-color-green: #71c075;
  --event-color-red: #fb8c7e;
  --event-color-soft-green: #a6d03f;
  --event-color-pink: #dc81ae;
  --event-color-yellow: #eacc5d;
  --event-color-dark-green: #72b191;
  --event-color-dark-red: #db5763;
  --event-color-medium-gray: #d2d6dc;
  --event-color-light-gray: #e5e7eb;
}

.event-icon-square {
  align-items: center;
  @apply rounded;
  color: white;
  display: inline-flex;
  height: 1.65em;
  min-height: 1.65em;
  justify-content: center;
  margin-right: 0.3em;
  width: 1.65em;
  min-width: 1.65em;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

/* non_work_day */
.event-bg-non_work_day,
.icon-bg-non_work_day {
  background: var(--event-color-non_work_day);
}
.event-border-non_work_day {
  border: 1px solid var(--event-color-non_work_day);
}
.event-text-non_work_day {
  color: var(--event-color-non_work_day);
}

/* public_holiday */
.event-bg-public_holiday,
.icon-bg-public_holiday {
  background: var(--event-color-public_holiday);
}
.event-border-public_holiday {
  border: 1px solid var(--event-color-public_holiday);
}
.event-text-public_holiday {
  color: var(--event-color-public_holiday);
}

/* birthday */
.event-bg-birthday,
.icon-bg-birthday {
  background: var(--event-color-birthday);
}
.event-border-birthday {
  border: 1px solid var(--event-color-birthday);
}
.event-text-birthday {
  color: var(--event-color-birthday);
}

/* gray */
.event-bg-gray,
.icon-bg-gray {
  background: var(--event-color-gray);
}
.event-border-gray {
  border: 1px solid var(--event-color-gray);
}
.event-text-gray {
  color: var(--event-color-gray);
}

/* dark-violet */
.event-bg-dark-violet,
.icon-bg-dark-violet {
  background: var(--event-color-dark-violet);
}
.event-border-dark-violet {
  border: 1px solid var(--event-color-dark-violet);
}
.event-text-dark-violet {
  color: var(--event-color-dark-violet);
}

/* hakuna-blue */
.event-bg-hakuna-blue,
.icon-bg-hakuna-blue {
  background: var(--event-color-hakuna-blue);
}
.event-border-hakuna-blue {
  border: 1px solid var(--event-color-hakuna-blue);
}
.event-text-hakuna-blue {
  color: var(--event-color-hakuna-blue);
}

/* blue */
.event-bg-blue,
.icon-bg-blue {
  background: var(--event-color-blue);
}
.event-border-blue {
  border: 1px solid var(--event-color-blue);
}
.event-text-blue {
  color: var(--event-color-blue);
}

/* violet */
.event-bg-violet,
.icon-bg-violet {
  background: var(--event-color-violet);
}
.event-border-violet {
  border: 1px solid var(--event-color-violet);
}
.event-text-violet {
  color: var(--event-color-violet);
}

/* soft-blue */
.event-bg-soft-blue,
.icon-bg-soft-blue {
  background: var(--event-color-soft-blue);
}
.event-border-soft-blue {
  border: 1px solid var(--event-color-soft-blue);
}
.event-text-soft-blue {
  color: var(--event-color-soft-blue);
}

/* orange */
.event-bg-orange,
.icon-bg-orange {
  background: var(--event-color-orange);
}
.event-border-orange {
  border: 1px solid var(--event-color-orange);
}
.event-text-orange {
  color: var(--event-color-orange);
}

/* green */
.event-bg-green,
.icon-bg-green {
  background: var(--event-color-green);
}
.event-border-green {
  border: 1px solid var(--event-color-green);
}
.event-text-green {
  color: var(--event-color-green);
}

/* red */
.event-bg-red,
.icon-bg-red {
  background: var(--event-color-red);
}
.event-border-red {
  border: 1px solid var(--event-color-red);
}
.event-text-red {
  color: var(--event-color-red);
}

/* soft-green */
.event-bg-soft-green,
.icon-bg-soft-green {
  background: var(--event-color-soft-green);
}
.event-border-soft-green {
  border: 1px solid var(--event-color-soft-green);
}
.event-text-soft-green {
  color: var(--event-color-soft-green);
}

/* pink */
.event-bg-pink,
.icon-bg-pink {
  background: var(--event-color-pink);
}
.event-border-pink {
  border: 1px solid var(--event-color-pink);
}
.event-text-pink {
  color: var(--event-color-pink);
}

/* yellow */
.event-bg-yellow,
.icon-bg-yellow {
  background: var(--event-color-yellow);
}
.event-border-yellow {
  border: 1px solid var(--event-color-yellow);
}
.event-text-yellow {
  color: var(--event-color-yellow);
}

/* dark-green */
.event-bg-dark-green,
.icon-bg-dark-green {
  background: var(--event-color-dark-green);
}
.event-border-dark-green {
  border: 1px solid var(--event-color-dark-green);
}
.event-text-dark-green {
  color: var(--event-color-dark-green);
}

/* dark-red */
.event-bg-dark-red,
.icon-bg-dark-red {
  background: var(--event-color-dark-red);
}
.event-border-dark-red {
  border: 1px solid var(--event-color-dark-red);
}
.event-text-dark-red {
  color: var(--event-color-dark-red);
}

/* medium-gray */
.event-bg-medium-gray,
.icon-bg-medium-gray {
  background: var(--event-color-medium-gray);
}
.event-border-medium-gray {
  border: 1px solid var(--event-color-medium-gray);
}
.event-text-medium-gray {
  color: var(--event-color-medium-gray);
}

/* light-gray */
.event-bg-light-gray,
.icon-bg-light-gray {
  background: var(--event-color-light-gray);
}
.event-border-light-gray {
  border: 1px solid var(--event-color-light-gray);
}
.event-text-light-gray {
  color: var(--event-color-light-gray);
}
