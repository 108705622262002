.c-multi-select {
  position: relative;

  @apply cursor-text;
  @apply w-full;

  &__list {
    @apply border border-gray-300 rounded-md shadow-sm bg-white;
    padding: var(--input-padding);

    &.disabled {
      @apply bg-gray-100 text-gray-500 cursor-not-allowed;
    }

    @apply flex flex-wrap gap-1 items-center gap-y-3;
    @apply text-sm;

    &[data-focus] {
      @apply border-blue-500;
      @apply ring-2 ring-blue-500 ring-opacity-50;
    }
  }

  &.invalid &__list {
    /* make sure this matches with the invalid styling in forms.css */
    @apply border-red-400 !important;
    @apply text-red-900 placeholder-red-300 !important;
    @apply focus:border-red-500 !important;
    @apply focus:ring-red-500 focus:ring-opacity-50 !important;
  }

  [data-list-entry='item'] {
    @apply bg-gray-200 text-gray-800 rounded-xl px-2 py-1 text-xs;
    @apply overflow-hidden;
    @apply cursor-auto;
    @apply -my-1;

    @apply flex items-center;

    span {
      @apply truncate;
    }

    a {
      @apply shrink-0 ml-1;
      @apply pointer-events-auto;
      @apply cursor-pointer;

      &::after {
        @apply w-3 h-3;
        @apply opacity-50;
        /* FontAwesome xmark */
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><path fill='currentColor' d='M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z'/></svg>");
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        display: block;

        @apply print:hidden;

        &:hover {
          @apply opacity-100;
        }
      }
    }
  }

  [data-list-entry='search'] {
    input {
      @apply p-0 m-0 rounded-none;

      &, &:focus {
        @apply border-0 shadow-none outline-none ring-0;
      }
    }
  }

  &__dropdown {
    background: white;
    @apply rounded;
    box-shadow: var(--dropdown-box-shadow);
    display: none;
    margin: 2px 0 0 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: var(--z-index-top);
    font-size: 1rem;
    overflow: hidden;
  }
}
