.c-button-group-select {
  > label:first-of-type > .c-button {
    @apply rounded-tr-none;
    @apply rounded-br-none;
  }

  > label:last-of-type > .c-button {
    @apply rounded-tl-none;
    @apply rounded-bl-none;
  }

  > label:not(:first-of-type):not(:last-of-type) > .c-button {
    @apply rounded-none;
  }
}
