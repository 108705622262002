.c-manage_user_tabs_information {
  dd p:last-child {
    @apply mb-0;
  }

  &__link {
    @apply border-l-4 px-3 py-2 flex items-center text-sm font-medium;
    @apply border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900;

    &:active,
    &:focus {
      @apply text-gray-900;
    }

    &--current {
      @apply bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700;

      &:focus {
        @apply text-blue-700;
      }
    }
  }
}
