#staff_calendar {
  --grid-z-index: 5;

  .sc-calendar {
    display: grid;
    grid-template-columns: 200px repeat(62, 1fr);
    grid-auto-rows: 40px;
  }

  .sc-grid {
    z-index: var(--grid-z-index);
  }

  .sc-header {
    z-index: calc(var(--grid-z-index) + 2); /* should not overlay flash */
    @apply sticky top-0;
  }

  .sc-today {
    z-index: calc(var(--grid-z-index) + 1);
  }

  .sc-clickarea {
    @apply cursor-pointer;
    z-index: calc(var(--grid-z-index) + 1);
  }

  .sc-event {
    @apply pointer-events-none;

    &[data-layer='foreground'] {
      z-index: calc(var(--grid-z-index) + 1);
      @apply flex items-center rounded-sm my-3 mx-1 px-px text-xs;
      @apply overflow-hidden; /* otherwise grid columns vary in width with small window size */

      &[data-starts='earlier'] { @apply ml-0 rounded-l-none; }
      &[data-ends='later'] { @apply mr-0 rounded-r-none; }
    }

    &[data-layer='background'] {
      z-index: calc(var(--grid-z-index) - 1);

      &[data-type='unemployment'] {
        @apply bg-gray-200 opacity-70;
      }

      &[data-type='weekly_schedule_absence'] {
        @apply bg-gray-200 opacity-70;
        &[data-wday='0'], &[data-wday='6'] { @apply bg-blue-100 opacity-100; } /* weekend */
      }

      &[data-type='public_holiday'] {
        @apply opacity-10;
        @apply bg-transparent;
        background-image: linear-gradient(-45deg, black 25%, transparent 25%, transparent 50%, black 50%, black 75%, transparent 75%, transparent);
        background-size: 10px 10px;
      }

      &[data-type='birthday'] {
        margin-left: auto;
        width: 0;
        height: 0;
        background: transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid var(--event-color-birthday);
      }
    }
  }

  .sc-overlap {
    z-index: calc(var(--grid-z-index) + 2);

    @apply pointer-events-none;
    @apply rounded-sm my-3 mx-1 flex items-center justify-center;
    @apply text-gray-900 font-semibold text-xs truncate;
  }
}
