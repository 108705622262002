.flatpickr-calendar {
  opacity: 0;
  display: none;
  visibility: hidden;
  position: absolute;
  background: #fff;
  padding: 8px;
  min-width: 240px;
  max-width: 250px;
  box-shadow: var(--dropdown-box-shadow);
  color: theme('colors.gray.800');

  @apply rounded-lg;

  @apply sm:fixed;
  @apply sm:inset-0 !important;
  @apply sm:py-2 sm:px-1;
  @apply sm:border-0 sm:rounded-none;
  @apply sm:w-full sm:max-w-full;
  @apply sm:h-screen;

}

.flatpickr-calendar.open {
  opacity: 1;
  visibility: visible;
  display: inline-block;
  z-index: calc(var(--z-index-top) + 1);
}

.flatpickr-months {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  @apply sm:mb-3;

  .flatpickr-prev-month,
  .flatpickr-next-month {
    display: flex;
    padding: 6px;

    @apply rounded-lg;

    &:hover {
      @apply lg:cursor-pointer;
      @apply lg:bg-blue-100;
    }

    svg {
      width: 1rem;
      height: 1rem;

      @apply sm:w-6 sm:h-6;
    }
  }

  .flatpickr-month {
    margin: 0 auto;
  }

  .flatpickr-monthDropdown-months {
    @apply py-1;
  }

  .flatpickr-current-month {
    display: flex;
    align-items: center;
    @apply text-sm;
  }

  .cur-month {
    @apply font-semibold;
    padding-left: 10px;
  }

  .numInput {
    width: 40px !important;
    border: 0;
    box-shadow: none;
    margin: 0 0 0 12px;
    padding: 0;
    -moz-appearance: textfield;

    &:hover,
    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background: white;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}

.flatpickr-weekdays {
  @apply mt-3 mb-1;
  .flatpickr-weekdaycontainer {
    display: flex;
    width: 100%;
  }

  .flatpickr-weekday {
    text-align: center;
    @apply text-xs;
    color: theme('colors.gray.600');
    text-transform: uppercase;
    width: calc(100% / 7);
  }
}

.flatpickr-days {
  .dayContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .flatpickr-day {
    @apply w-1/7;
    @apply text-sm text-center;
    @apply border-2 border-white;
    @apply font-semibold;
    @apply sm:text-base;
    @apply sm:py-1 sm:px-0;

    &.today {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-top: 8px solid theme('colors.blue.600');
        border-right: 0 solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 8px solid transparent;
      }
    }

    &:hover,
    &:focus {
      background: theme('colors.blue.100');
      border-color: theme('colors.blue.100');
      cursor: pointer;
      outline: none;
    }

    &.selected {
      background: theme('colors.blue.600');
      border-color: theme('colors.blue.600');
      color: white;

      &.flatpickr-day.today {
        &::after {
          border-top-color: white;
        }
      }
    }

    &.flatpickr-disabled {
      background: theme('colors.gray.100');
      border-color: theme('colors.gray.100');

      &:hover {
        cursor: not-allowed;
        background: theme('colors.gray.100');
        border-color: theme('colors.gray.100');
      }
    }
  }

  .prevMonthDay,
  .nextMonthDay {
    color: theme('colors.gray.600');
  }
}
