.c-manage_user_charts_statutory_overtimes {
  @apply relative; /* for year selector */

  .c-manage_user_charts_year-selector { @apply hidden; }
  &:hover { .c-manage_user_charts_year-selector { @apply inline-block; } }

  .bar {
    fill: theme('colors.blue.500');

    &.warning {
      fill: theme('colors.yellow.400');
    }

    &.danger {
      fill: theme('colors.red.300');
    }
  }

  .limit {
    stroke: theme('colors.gray.400');
    stroke-dasharray: 2, 1;
  }
}
