.icon3000 {
  @apply inline-flex justify-center;

  height: 1em;
  width: 1em;
  vertical-align: -0.125em;
  overflow: visible;
  box-sizing: content-box;

  svg {
    @apply h-full w-full;
    @apply pointer-events-none;
  }
}
