[data-controller="mobile--utils--jump-to-date"] {
  input[type="date"] {
    @apply absolute top-0 bottom-0;
    @apply w-full;
    @apply shadow-none border-0 bg-transparent focus:ring-0;
    @apply py-1;
  }

  input::-webkit-datetime-edit,
  input::-webkit-date-and-time-value {
    @apply hidden;
  }
}
